<template>
  <div>
    <!-- pic405261AA-CC05-4D10-9807-8F22FEC54095.jpg -->
    <img style="width: 200px; height: 283px; float: left; margin-right: 10px;" src="@/assets/news-network/create-digital-designs-the-lu-way-2.jpg" class="rounded">
    <p>
      You’re tuned in to part two of a special LEGO Universe News Network series on how to make your own amazing in-game art! In this edition we’ll learn from lead LEGO Universe concept artist, Jerry Meyer!
    </p>
    <p>
      Jerry reports that his team collaborates with art director Phillip Atencio to zero in on the look and feel of everything from the gameworld’s diverse environments to its LEGO features, creatures and characters.
    </p>
    <p>
      One technique Jerry’s team has been using to help mold LEGO Universe is to concept large areas in clay. Diehard LEGO fans may be thinking, “Clay?! I thought this was a LEGO MMOG!” Jerry explains, “Even though clay can be kind of crude, these models let a lot of us get our hands dirty to quickly shape the area; they make a great springboard for mapping out the relationships of all the spaces.
    </p>
    <!-- pic870BC7A8-D6DE-46A6-B9C8-C63610EC9391.jpg -->
    <img style="width: 440px; height: 275px;" src="@/assets/news-network/create-digital-designs-the-lu-way-2-1.jpg" class="rounded">

    <p>
      <font size="1"><i>Example of clay concept model</i></font>
    </p>
    <p>
      “As we do that, we’ll talk more about the LEGO elements that will go in the different areas. Team members will shout out things like, ‘This might be a good spot for the gateway!’ or ‘The ship’s dock could go over there!’ We’ll usually draw more detailed concept art of those in-game elements.”
    </p>
    <p>
      Jerry’s team turned out a stack of such concept drawings to help make a training-mech strong enough for launch in LEGO Universe.
    </p>
    <div class="clear"></div>

    <!-- picCC0A34F8-1572-4FBC-8C70-697D7CA60DFA.jpg -->
    <img style="width: 440px; height: 330px;" src="@/assets/news-network/create-digital-designs-the-lu-way-2-2.jpg" class="rounded">
    <p>
      <font size="1"><i>Concept drawings of various design phases</i></font>
    </p>

    <p>
      After LEGO Universe concept artists helped define the initial look of the battle-trainer, world artists built it with physical bricks. They then improved on their first physical model before creating a digital version of the mechanized marvel.
    </p>
    <p>
      Next, the LEGO Universe art team brought the battle-trainer alive with animation— and ran into a snag: Although the physical model of the immense mech had read very well, the virtual version looked less fierce after animation!
    </p>
    <div class="clear"></div>

    <!-- pic7F5B85D2-19FF-4685-A0ED-15BF34BB1CAB.jpg -->
    <img style="width: 440px; height: 330px;" src="@/assets/news-network/create-digital-designs-the-lu-way-2-3.jpg" class="rounded">
    <p><font size="1"><i>Physical and digital models</i></font></p>

    <p>
      The LEGO Universe art team decided to explore further by making more concept drawings. Phillip Atencio says going ‘back to the drawing board’ took more time, but his team happily pushes its creative limits to help build the best possible game!
    </p>
    <p>
      Lock your web browser right here on the LEGO Universe News Network to catch part three of this special series. Lead LEGO Universe world artist Nathan Storm will teach us how to overcome a few of the challenges his team faces while building with physical LEGO bricks!
    </p>
    <div class="clear"></div>

    <!-- pic2E8B9633-5B03-4A89-AFC6-96408157D2EC.jpg -->
    <img style="width: 100px; height: 75px; float: left; margin-right: 10px;" src="@/assets/news-network/create-digital-designs-the-lu-way-2-4.jpg" class="rounded">

    <p>
      <i><b>This edition’s LEGO Universe building tips: </b></i>
      <br>• Get your creative vision as clear as possible before building!
      <br>• Persistently improve your masterpiece!
      <br>• Funny creations make for maximum fun!
    </p>
    <p>
      <i>
        Follow this link if you missed
        <router-link :to="{ name: 'story', params: {id: 82873} }">part one</router-link>
        of this special series!
      </i>
    </p>
    <div class="clear"></div>
  </div>
</template>
